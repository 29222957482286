<template>
  <div class="all">
    <img src="../assets/logo.png" alt="" class="img mr-5"/>
     
    <div class="box">

        <p class="title"> <img src="../assets/monday.png" class="icon"/> Connect to Monday.com </p>
              <br> <br>
       <b-alert :show="message === ''" variant="success"
          >Thanks for connecting to Monday.com. This window will close automatically in a few seconds...</b-alert
        >
        <b-alert :show="message!==''" variant="danger"
          >{{message}}</b-alert
        >
    </div>
    <p class="address"> © {{year}} Altosio Software, Atelierstraße, 81671 Munich, Germany</p>
  </div>
</template>

<script> 
export default {
  name: "ConsentMondayApp",
  data() {
    return {
      message: '',
        year: new Date().getFullYear(), 
    };
  },
 mounted() {
    this.getMessage()
  },
  methods:{
    getMessage(){
      //Windows location of shape : http://app.altosio.com/MondayAppConsent?code=f42396f3ebeadfb95c5311bfb6647380&region=use1&scope=me%....tags%3Aread%20teams%3Aread&state=
      var  url = window.location.href;
      if(window.location.href.includes('?code='))
      {
        var indexOfCode = url.indexOf('?code=');
        var firstIndexOfAndSign = url.indexOf('&');
        var code = url.substring(indexOfCode + 6,firstIndexOfAndSign);
        window.opener.document.getElementById("MondayCodeId").value = code;
        setTimeout("window.close()", 3000);
      }
      else{
        //What to put here in case of error?
      }
    }
  }
};
</script>

<style scoped>
.all {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: #f2f6ff;
  text-align: center;
}
.img {
  margin: auto;
  width: 253px;
  height: 75px;
  margin-top: 80px;
  cursor: pointer;
}
.box {
  margin: auto;
  width: 600px;
  height: 400px;
  margin-top: 35px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 4px 4px 15px rgba(225, 225, 225, 0.5);
}
.title {
  margin: auto;
  margin-top: 50px;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #23438e;
}
.address {
font-family: Roboto-Regular,Helvetica,Arial,sans-serif;
color: rgba(0,0,0,0.54);
font-size: 11px;
line-height: 18px;
padding-top: 12px;
text-align: center;
}
.rowmargin{
  margin-left: 350px;
}
.hint {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #859ec0;
}
.shape {
  margin-left: 55px;
  width: 472px;
  height: 56px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  background-repeat: no-repeat;
  text-indent: 40px;
}
.profile {
  background-image: url("../assets/profile.png");
  background-position: 15px 15px;
}

.email {
  background-image: url("../assets/Vector.png");
  background-position: 15px 18px;
}
.password {
  background-image: url("../assets/lock.png");
  background-position: 15px 16px;
}
.company {
  background-image: url("../assets/company.png");
  background-position: 15px 16px;
}
.country {
  background-image: url("../assets/country.png");
  background-position: 15px 16px;
}
.botton {
  margin: auto;
  margin-top: 50px;
  width: 492px;
  height: 56px;
  background: #23438e;
  border-radius: 5px;
}
.reset {
  margin-right: 75px;
  margin-top: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #23438e;
  cursor: pointer;
}
.signin {
  font-weight: 600;
  color: #23438e;
  cursor: pointer;
}
</style>
